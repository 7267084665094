.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}





/*********custom***********/

.academic-title {
  margin-left: 20px;
}

.logo a {
  text-align: center;
}

.logo img {
  width: 90%;
}

.counter-thumb1 {
  width: 150px;
}

.text {
  border-style: solid;
  border-width: 1px;
  border-color: #f0f0f0;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.06);
  padding: 0 15px;

}

.menu {
  text-align: left;
}

.banner-heading {
  z-index: 25;
}

.news {
  background-image: url(./../public/assets/images/about/notebook.jpg);
  background-size: cover;
}

.teachers-bg {
  border: solid rgb(0, 0, 0) 1px;
  border-radius: 8px;
  background-color: rgba(131, 161, 218, 0.308);
  aspect-ratio: 1/1;
  background-size: cover;
}

.teacher-img {
  aspect-ratio: 1/1;
  border: solid rgb(0, 0, 0) 0.5px;
  border-radius: 8px;
}

.teacher-name {
  color: black;
  font-size: 14px !important;
}

.teacher-edu {
  color: black;
  font-size: 14px !important;
}

.office {
  padding: 10px 20px;
  background-image: url(./../public/assets/images/ofiicial-bg.jpg);
}

.office-img {
  height: 150px;
  aspect-ratio: 1/1;
  margin: auto;
  border-radius: 20px;
}

.office-span {
  border: solid white 2px;
  border-radius: 20px;
}



.logo {
  width: 100lvw !important;
}

.logo-img {
  height: 70px;
  width: 70px !important;
}

.header-bottom {
  z-index: 999;
}

@media only screen and (min-width: 992px) {
  .container {
    width: 100%;
  }

  .footer-top {
    padding-top: 20px;
    padding-bottom: 30px;
    background-color: #9e419b;
    color: white;
    text-align: justify;
  }

  .footer-top>li,
  .title {
    color: white !important;
  }

  .footer-widget>ul>li>a {
    color: white !important;
  }

  .banner-heading {
    font-size: 55px;
  }

  .logo>a {
    justify-content: center !important;
  }

  .menu-area {
    justify-content: center;
  }

  .menu {
    padding-left: 0px !important;
  }

  .menu>li>a {
    font-size: 12px !important;
  }

  .logo {
    padding-right: 0px;
    padding-left: 0px;
  }

  .logo-img {
    margin-right: 20px !important;
  }

  .c-name {
    font-size: 30px;
    padding: 10px 5px;
    font-size: 30px;
  }

  .c-place {
    font-size: 22px;
  }

}

.message {
  color: red;
}

.btn {
  width: fit-content;
  height: fit-content;
}


@media only screen and (max-width: 768px) {
  header h1 {
    font-size: 3rem;
  }

  .c-name {
    font-size: 30px;
  }

  .office-span {
    font-size: 11px;
  }

  .office-name {
    font-size: 14px !important;
  }

  .logo {
    padding-right: 0px;
    padding-left: 0px;
  }

  .logo-img {
    margin-right: 20px !important;
  }

  .c-name {
    padding: 10px 5px;
    font-size: 24px;
  }

  .c-place {
    font-size: 18px;
  }

  /* .office-span{
    font-size: 11px;
  }
  .office-name{
    font-size: 14px !important;
  } */

  .about-thumb>p {
    font-size: 10px;
    padding-left: 40px !important;
    padding-right: 45px !important;
    text-align: justify !important;
    line-height: 20px;
  }
}

@media only screen and (max-width: 576px) {
  header h3 {
    font-size: 17px;

  }

  header h4 {
    font-size: 15 !important;
  }

  .logo {
    margin-left: 10px;
    ;
  }

  .logo-img {
    width: 40px !important;
    height: 40px;
    margin: 0px !important;
  }
}

@media only screen and (max-width: 425px) {

  /* header h1 {
    font-size: 3rem;
  } */
  .logo {
    padding-right: 0px;
    padding-left: 0px;
  }

  .c-name {
    padding: 10px 5px;
    font-size: 14px;
  }

  .c-place {
    font-size: 12px;
  }

  /* .office-span{
    font-size: 11px;
  }
  .office-name{
    font-size: 14px !important;
  } */

  .about-thumb>p {
    font-size: 10px;
    padding-left: 40px !important;
    padding-right: 45px !important;
    text-align: justify !important;
    line-height: 20px;
  }
}

@media only screen and (max-width: 375px) {

  /* header h1 {
    font-size: 3rem;
  } */
  .logo {
    padding-right: 0px;
    padding-left: 0px;
  }

  .c-name {
    padding: 10px 5px;
    font-size: 12px;
  }

  .c-place {
    font-size: 10px;
  }

  /* .office-span{
    font-size: 11px;
  }
  .office-name{
    font-size: 14px !important;
  } */

  .about-thumb>p {
    font-size: 10px;
    padding-left: 40px !important;
    padding-right: 45px !important;
    text-align: justify !important;
    line-height: 20px;
  }
}

@media only screen and (max-width: 320px) {

  /* header h1 {
    font-size: 3rem;
  } */
  .logo {
    padding-right: 0px;
    padding-left: 0px;
  }

  .c-name {
    padding: 10px 5px;
    font-size: 12px;
  }

  .c-place {
    font-size: 10px;
  }

  /* .office-span{
    font-size: 11px;
  }
  .office-name{
    font-size: 14px !important;
  } */

  .about-thumb>p {
    font-size: 10px;
    text-align: justify !important;
    line-height: 20px;
  }
}